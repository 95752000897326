@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.bottom {
  padding: 25px 0;
  background-color: variable.$whiteColor;
  border-top: 1px solid variable.$primaryColor;
  .col {
    width: 17%;
    text-align: left;
    margin: 0 1%;
    &.collogo {
      width: 70%;
      margin: 0;
    }
    span {
      font-family: variable.$primaryFontBold;
      color: variable.$primaryColor;
      font-size: variable.$size18;
      margin-bottom: 10px;
      letter-spacing: 0;
      display: block;
      text-align: left;
    }
    li {
      display: block;
      a {
        font-family: variable.$primaryFont;
        color: variable.$blackColor;
        font-size: 14px;
        margin-bottom: 7px;
        display: block;
        text-align: left;
      }
      &:last-child {
        a {
          margin-bottom: 0;
        }
      }
    }
  }
}
.last {
  padding-top: 45px;
  padding-bottom: 50px;
  padding-left: 54.5%;
  .flex {
    justify-content: left;
    .reseaux {
      margin-right: 20px;
      li {
        display: inline-block;
        vertical-align: top;
        a {
          margin: 0 7px;
          img {
            display: inline-block;
            vertical-align: middle;
          }
        }
      }
    }
    a,
    p {
      font-family: variable.$primaryFontBold;
      color: variable.$primaryColor;
      font-size: 13px;
      line-height: 23px;
      text-decoration: none;
      text-transform: uppercase;
    }
  }
}

@include mixin.mediaTablet() {
  .bottom {
    padding: 15px 2% 25px;
  }
  .last {
    .flex {
      padding-left: 0;
    }
  }
}
@include mixin.mediaSmallTab() {
  .bottom {
    .col {
      width: 24%;
      &.collogo {
        width: 28%;
      }
    }
  }
}
@include mixin.mediaMobile() {
  .bottom {
    .flex {
      flex-wrap: wrap;
      margin: 0 auto;
      max-width: 90%;
    }
    .col {
      width: 35%;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      justify-content: left;
      margin-bottom: 0;
      &.choose {
        width: 50%;
      }
      &.collogo {
        width: 100%;
        margin-bottom: 30px;
        img {
          max-width: 150px;
        }
      }
      &.infos {
        order: 3;
      }
      span {
        text-align: left;
        padding-right: 0;
        box-sizing: border-box;
        margin-bottom: 3px;
      }
      ul {
        width: 140px;
        padding-top: 8px;
      }
    }
  }
  .last {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    .flex {
      flex-direction: row;
      padding-top: 25px;
      flex-wrap: nowrap;
      justify-content: flex-start;
      > div {
        text-align: left;
        width: auto;
        max-width: 215px;
      }
      li a:first-child {
        margin-left: 0;
      }
    }
  }
}
