@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.bar {
  background-color: variable.$yellowColor;
  font-family: variable.$primaryFontBold;
  font-size: variable.$size15;
  color: variable.$primaryColor;
  padding: 10px;
  padding-inline: max(10px, calc(50% - 510px));
  .wrapper {
    text-align: center;
    .progressBar {
      margin-bottom: 10px;
    }
    .text {
      margin-bottom: 10px;
      text-align: center;
      br {
        display: none;
      }
    }
    .score {
      flex: 1;
      .flex {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
        span {
          font-size: variable.$size12;
          flex: 1;
          max-width: 95px;
        }
        ul {
          display: flex;
          gap: 6px;
          li {
            span {
              display: flex;
              width: 36px;
              height: 36px;
              border-radius: 36px;
              border: 2px solid variable.$primaryColor;
              color: variable.$primaryColor;
              align-items: center;
              justify-content: center;
              font-family: variable.$primaryFontBold;
              font-size: variable.$size18;
              background-color: variable.$whiteColor;
              cursor: pointer;
              &:hover,
              &.active {
                background-color: variable.$primaryColor;
                color: variable.$whiteColor;
              }
            }
          }
        }
      }
    }
    .action {
      margin-top: 15px;
      button {
        width: 130px;
        margin-inline: auto;
        box-shadow: none;
      }
    }
  }
}
@include mixin.mediaTabletSize() {
  .bar {
    .wrapper {
      display: flex;
      align-items: center;
      gap: 10px;
      .progressBar {
        margin: 0;
      }
      .score {
        .flex {
          gap: 15px;
          span {
            white-space: nowrap;
            flex: none;
          }
          ul {
            gap: 15px;
          }
        }
      }
      .action {
        margin-top: 0;
      }
      .text {
        margin: 0;
        text-align: left;
        br {
          display: block;
        }
      }
    }
  }
}
@include mixin.mediaDesktopSize() {
  .bar {
    font-size: variable.$size18;
  }
}
